import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import 'react-bootstrap';

const VideoCall = () => {
    const localVideoRef = useRef();
    const remoteVideoRef = useRef();
    const socketRef = useRef();
    const [calling, setCalling] = useState(false);
    const [callAccepted, setCallAccepted] = useState(false);

    useEffect(() => {
        socketRef.current = io();

        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(stream => {
                localVideoRef.current.srcObject = stream;
                console.log(socketRef.current);
                // Join video call room
                socketRef.current.emit('join', { room: 'video-call' });

                // Listen for incoming offer from peer
                socketRef.current.on('offer', offer => {
                    // Handle offer
                    // Display remote video
                    console.log('in offer');
                    remoteVideoRef.current.srcObject = offer.stream;
//                    setInCall(true);
                });

                // Listen for incoming answer from peer
                socketRef.current.on('answer', answer => {
                    // Handle answer
                    // Display remote video
                    console.log('in answer');
                    remoteVideoRef.current.srcObject = answer.stream;
//                    setInCall(true);
                });

                // Listen for incoming ICE candidate from peer
                socketRef.current.on('ice-candidate', candidate => {
                    console.log('in ice-candidate');
                    // Handle ICE candidate
                });
            })
            .catch(error => console.error('getUserMedia error:', error));

        return () => {
            socketRef.current.disconnect();
        };
    }, []);

    const handleCallClick = () => {
        setCalling(true);
        socketRef.current.emit('offer');
//        socketRef.current.emit('call', { room: 'video-call' });
    };

    const handleAcceptCall = () => {
        setCallAccepted(true);
        socketRef.current.emit('accept-call', { room: 'video-call' });
    };

    const handleRejectCall = () => {
        setCalling(false);
        socketRef.current.emit('reject-call', { room: 'video-call' });
    };
    
    console.log('calling->'+calling);
    console.log('callAccepted->'+callAccepted);
    console.log(localVideoRef);
    console.log(remoteVideoRef);
    
    return (
        <div>
            <video ref={localVideoRef} autoPlay muted></video>
            {calling && !callAccepted && (
                <div>
                    <button onClick={handleAcceptCall}>Accept Call</button>
                    <button onClick={handleRejectCall}>Reject Call</button>
                </div>
            )}
            {callAccepted && (
                <div>
                    <video ref={remoteVideoRef} autoPlay></video>
                    <button onClick={() => setCallAccepted(false)}>End Call</button>
                </div>
            )}
            {!calling && !callAccepted && <button onClick={handleCallClick}>Make Call</button>}
        </div>
    );
};

export default VideoCall;