import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VideoCall from './videocall'; // Import your VideoCall component
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route path="/video-call" element={<VideoCall />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
